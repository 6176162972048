/* App.css */

.app-container {
    display: flex;
    width: 100%;
    height: 100vh;
}

.wheel-section {
    flex: 2; /* Ekranın 2/3'lük kısmını kaplayacak */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* İsteğe bağlı: arka plan rengi */
}

.features-section {
    flex: 1; /* Ekranın 1/3'lük kısmını kaplayacak */
    padding: 20px;
    background-color: #f9f9f9; /* İsteğe bağlı: arka plan rengi */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}


