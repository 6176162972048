.container {
    display: flex;
    width: 100%;
    height: 100vh;
}

.wheel-section {
    flex: 2; /* Ekranın 2/3'ünü kaplayacak */
    display: flex;
    justify-content: center;
    align-items: center;
}

.wheel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wheel-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wheel-pointer {
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    z-index: 1;
    width: 40px;
    height: 40px;
    background-image: url("/public/pointer-icon.svg");
    background-size: cover;
}

.spin-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.winning-team {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}
